import {
  defineNuxtRouteMiddleware,
  navigateTo,
} from 'nuxt/app';
import { useAuthStore } from '@stores/useAuthStore';

export default defineNuxtRouteMiddleware(async () => {
  const { refreshUser } = useAuthStore();
  const { accessToken, authenticated, isLoading } = toRefs(useAuthStore());

  if (!accessToken.value) {
    await refreshUser();
  }

  if (!isLoading && !authenticated) {
    return navigateTo({ path: '/login' });
  }
});
